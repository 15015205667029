import { lazy } from "react";
import { TConfigRouter } from "./interfaces";

// pages
const Home = lazy(() => import("../pages/Home"));
const RequestPolicy = lazy(() => import("../pages/RequestPolicy"));
const Policies = lazy(() => import("../pages/Policies"));
const MyPolicies = lazy(() => import("../pages/MyPolicies"));
const ScOwnerPolicies = lazy(() => import("../pages/UnderwriterPolicies"));
const SetOracle = lazy(() => import("../pages/SetOracle"));
const OraclePolicies = lazy(() => import("../pages/OraclePolicies"));

export const routes: TConfigRouter[] = [
  {
    path: "/",
    element: Home,
  },
  {
    path: "/request-policy",
    element: RequestPolicy,
    isAbleToAccess: "user",
  },
  {
    path: "/policies",
    element: Policies,
  },
  {
    path: "/my-policies",
    element: MyPolicies,
    isAbleToAccess: "user",
  },
  {
    path: "/smart-contract-owner-policies",
    element: ScOwnerPolicies,
    isAbleToAccess: "scOwner",
  },
  {
    path: "/oracle-policies",
    element: OraclePolicies,
    isAbleToAccess: "oracle",
  },
  {
    path: "/set-oracle",
    element: SetOracle,
    isAbleToAccess: "scOwner",
  },
];
