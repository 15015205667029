import { useMemo } from "react";
import { useAppSelector } from "../redux/store";
export const useLoggedAccount = () => {
  const { smartContractOwner, connectedUser, oracle } = useAppSelector(
    (state) => state.systemState
  );

  const isSmartContractOwner = useMemo(() => {
    if (connectedUser && smartContractOwner) {
      return smartContractOwner.toLowerCase() === connectedUser.toLowerCase();
    }
    return false;
  }, [connectedUser, smartContractOwner]);

  const isOracle = useMemo(() => {
    if (connectedUser && oracle) {
      return oracle.toLowerCase() === connectedUser.toLowerCase();
    }
    return false;
  }, [connectedUser, oracle]);

  const typeLoggedAccount = useMemo(() => {
    if (connectedUser) {
      if (isSmartContractOwner) {
        return "scOwner";
      }
      if (isOracle) {
        return "oracle";
      }
      return "user";
    }
    return "";
  }, [connectedUser]);

  return { isSmartContractOwner, typeLoggedAccount };
};
