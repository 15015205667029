import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/store";
import {
  checkConnectWallet,
  getConnectedUser,
  getSmartContractAccounts,
  handleAccountChanged,
} from "../redux/systemState/systemStateSlice";
export const useDetackMetaMask = () => {
  const dispatch = useAppDispatch();
  const { isInstalled } = useAppSelector((state) => state.systemState);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(checkConnectWallet());
  }, []);

  useEffect(() => {
    if (isInstalled) {
      dispatch(getConnectedUser());
      dispatch(getSmartContractAccounts());
      window.ethereum.on("chainChanged", handleChainChanged);
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      return () => {
        window.ethereum.removeListener("chainChanged", handleChainChanged);
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      };
    }
  }, [isInstalled]);

  const handleChainChanged = () => {
    navigate("/");
    window.location.reload();
  };

  const handleAccountsChanged = (accounts: any[]) => {
    dispatch(handleAccountChanged(accounts[0]));
    dispatch(getSmartContractAccounts());
    navigate("/");
  };
};
