import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BigNumber, ethers } from "ethers";
import { getContractForSigner, getContract, mapPolicyDtoToPolicy } from "ultils";
import { Policy, RequestPolicy } from "interfaces";

export type Policies = {
  [key: string]: string | Policy[];
  name: string;
  activePolicies: Policy[];
  myPolicies: Policy[];
  offeredPolicies: Policy[];
  voidedPolicies: Policy[];
  underwrittenPolicies: Policy[];
  expiredPolicies: Policy[];
  confirmedPolicies: Policy[];
  paidOutPolicies: Policy[];
};

const initialState: Policies = {
  name: "policies",
  activePolicies: [],
  myPolicies: [],
  offeredPolicies: [],
  voidedPolicies: [],
  underwrittenPolicies: [],
  expiredPolicies: [],
  confirmedPolicies: [],
  paidOutPolicies: [],
};

const convertEventToPolicies = (events: ethers.Event[]) => {
  const policies = events.map((event) => {
    if (event.args) {
      return mapPolicyDtoToPolicy(event.args[0]);
    }
    return undefined;
  });
  return policies.filter(Boolean) as Policy[];
};

export const getActivePolicies = createAsyncThunk(
  "policies/getActivePolicies",
  async () => {
    const contract = getContract();
    if (contract) {
      try {
        const policiesUnderWritten = contract.filters.PolicyUnderwritten();
        const perilEventConfirmed = contract.filters.PerilEventConfirmed();
        const events = await Promise.all([
          contract.queryFilter(policiesUnderWritten),
          contract.queryFilter(perilEventConfirmed),
        ]);
        return convertEventToPolicies(events.flat());
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const getOfferedPolicies = createAsyncThunk(
  "policies/getOfferedPolicies",
  async () => {
    const contract = getContract();
    if (contract) {
      try {
        const policiesOffered = contract.filters.PolicyOffered();
        const events = await contract.queryFilter(policiesOffered);
        return convertEventToPolicies(events);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const getVoidedPolicies = createAsyncThunk(
  "policies/getVoidedPolicies",
  async () => {
    const contract = getContract();
    if (contract) {
      try {
        const policiesVoided = contract.filters.PolicyVoided();
        const events = await contract.queryFilter(policiesVoided);
        return convertEventToPolicies(events);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const getUnderwrittenPolicies = createAsyncThunk(
  "policies/getUnderwrittenPolicies",
  async () => {
    const contract = getContract();
    if (contract) {
      try {
        const policiesUnderWritten = contract.filters.PolicyUnderwritten();
        const events = await contract.queryFilter(policiesUnderWritten);
        return convertEventToPolicies(events);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const getExpiredPolicies = createAsyncThunk(
  "policies/getExpiredPolicies",
  async () => {
    const contract = getContract();
    if (contract) {
      try {
        const expiredPolicies = contract.filters.UnderwrittenPolicyExpired();
        const events = await contract.queryFilter(expiredPolicies);
        return convertEventToPolicies(events);
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const getConfirmedPolicies = createAsyncThunk(
  "policies/getConfirmedPolicies",
  async (loggedAccount: string) => {
    const contract = getContract();
    if (contract) {
      try {
        const policiesConfirmed = contract.filters.PerilEventConfirmed();
        const events = await contract.queryFilter(policiesConfirmed);
        return convertEventToPolicies(events).filter((item) => {
          return (
            item.insuredAddress.toLowerCase() === loggedAccount.toLowerCase()
          );
        });
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const getMyPolicies = createAsyncThunk(
  "policies/getMyPolicies",
  async (loggedAccount: string) => {
    const contract = getContract();
    if (contract) {
      try {
        const policiesOffered = contract.filters.PolicyOffered();
        const policiesVoided = contract.filters.PolicyVoided();
        const policiesUnderWritten = contract.filters.PolicyUnderwritten();
        const perilEventConfirmed = contract.filters.PerilEventConfirmed();
        const policiesPaidOut = contract.filters.PolicyPaidOut();
        const underwrittenPolicyExpired =
          contract.filters.UnderwrittenPolicyExpired();
        const events = await Promise.all([
          contract.queryFilter(policiesOffered),
          contract.queryFilter(policiesVoided),
          contract.queryFilter(policiesUnderWritten),
          contract.queryFilter(perilEventConfirmed),
          contract.queryFilter(policiesPaidOut),
          contract.queryFilter(underwrittenPolicyExpired),
        ]);

        return convertEventToPolicies(events.flat()).filter((item) => {
          return (
            item.insuredAddress.toLowerCase() === loggedAccount.toLowerCase()
          );
        });
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }
);

export const offerPolicy = createAsyncThunk(
  "policies/offerPolicy",
  async ({ signer, policy }: { signer?: string; policy: RequestPolicy }) => {
    const contract = getContractForSigner(signer);
    if (signer && contract) {
      const {
        ipfsHash,
        premiumAmount,
        underwriteAmount,
        durationInMilliseconds,
        insuredAddress,
      } = policy;

      return new Promise((resolve, reject) => {
        contract
          .offerPolicy(
            ipfsHash,
            premiumAmount,
            underwriteAmount,
            durationInMilliseconds,
            insuredAddress,
            {
              value: underwriteAmount,
            }
          )
          .then((data: any) => {
            resolve(data);
          })
          .catch((err: any) => {
            reject(err.reason || err.message || "");
          });
      });
    }
  }
);

export const payPremium = createAsyncThunk(
  "policies/payPremium",
  async ({ signer, policy }: { signer?: string; policy: Policy }) => {
    const contract = getContractForSigner(signer);
    if (contract) {
      return new Promise((resolve, reject) => {
        contract
          .payPremium(policy.ipfsHash, {
            gasLimit: BigNumber.from(999999),
            value: policy.premiumAmount,
          })
          .then((data: any) => {
            resolve(data);
          })
          .catch((err: any) => {
            console.log(err);
            reject(err.reason || err.message || "");
          });
      });
    }
  }
);

export const voidPolicy = createAsyncThunk(
  "policies/voidPolicy",
  async ({ signer, policy }: { signer?: string; policy: Policy }) => {
    const contract = getContractForSigner(signer);
    if (contract) {
      return new Promise((resolve, reject) => {
        contract
          .voidPolicy(policy.ipfsHash)
          .then((data: any) => {
            resolve(data);
          })
          .catch((err: any) => {
            reject(err.reason || err.message || "");
          });
      });
    }
  }
);

export const confirmPerilEvent = createAsyncThunk(
  "policies/confirmPerilEvent",
  async ({ signer, policy }: { signer?: string; policy: Policy }) => {
    const contract = getContractForSigner(signer);
    if (contract) {
      return new Promise((resolve, reject) => {
        contract
          .confirmPerilEvent(policy.ipfsHash, { gasLimit: BigNumber.from(999999) })
          .then((data: any) => {
            resolve(data);
          })
          .catch((err: any) => {
            reject(err.reason || err.message || "");
          });
      });
    }
  }
);

export const expireUnderwrittenPolicy = createAsyncThunk(
  "policies/expireUnderwrittenPolicy",
  async ({ signer, policy }: { signer?: string; policy: Policy }) => {
    const contract = getContractForSigner(signer);
    if (contract) {
      return new Promise((resolve, reject) => {
        contract
          .expireUnderwrittenPolicy(policy.ipfsHash, { gasLimit: BigNumber.from(999999) })
          .then((data: any) => {
            resolve(data);
          })
          .catch((err: any) => {
            reject(err.reason || err.message || "");
          });
      });
    }
  }
);

export const payoutPolicy = createAsyncThunk(
  "policies/payoutPolicy",
  async ({ signer, policy }: { signer?: string; policy: Policy }) => {
    const contract = getContractForSigner(signer);
    if (contract) {
      return new Promise((resolve, reject) => {
        contract
          .payoutClaim(policy.ipfsHash, { gasLimit: BigNumber.from(9999999) })
          .then((data: any) => {
            resolve(data);
          })
          .catch((err: any) => {
            reject(err.reason || err.message || "");
          });
      });
    }
  }
);

export const policiesSlice = createSlice({
  name: "policies",
  initialState,
  reducers: {
    //U1 add more offered policies from the event of smart contract
    addOfferedPolicies: (state, action) => {
      state.offeredPolicies = [action.payload, ...state.offeredPolicies];
    },
    addVoidedPolicies: (state, action) => {
      const policy = action.payload as Policy;
      state.voidedPolicies = [policy, ...state.voidedPolicies];
      state.offeredPolicies = [...state.offeredPolicies.filter(p => p.ipfsHash !== policy.ipfsHash)];
    },
    //U1 add more active policies from events of smart contract
    addActivePolicies: (state, action) => {
      state.activePolicies = [action.payload, ...state.activePolicies];
    },
    addMyPolicies: (state, action) => {
      state.myPolicies = [action.payload, ...state.myPolicies];
    },
    addUnderwrittenPolicies: (state, action) => {
      state.underwrittenPolicies = [
        action.payload,
        ...state.underwrittenPolicies,
      ];
    },
    addExpiredPolicies: (state, action) => {
      state.expiredPolicies = [action.payload, ...state.expiredPolicies];
    },
    addConfirmedPolicies: (state, action) => {
      state.confirmedPolicies = [action.payload, ...state.confirmedPolicies];
    },
    addPayoutPolicies: (state, action) => {
      state.paidOutPolicies = [action.payload, ...state.paidOutPolicies];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActivePolicies.fulfilled, (state, action) => {
      state.activePolicies = action.payload;
    });
    builder.addCase(getMyPolicies.fulfilled, (state, action) => {
      state.myPolicies = action.payload;
    });
    builder.addCase(getOfferedPolicies.fulfilled, (state, action) => {
      state.offeredPolicies = action.payload;
    });
    builder.addCase(getVoidedPolicies.fulfilled, (state, action) => {
      state.voidedPolicies = action.payload;
    });
    builder.addCase(getUnderwrittenPolicies.fulfilled, (state, action) => {
      state.underwrittenPolicies = action.payload;
    });
    builder.addCase(getExpiredPolicies.fulfilled, (state, action) => {
      state.expiredPolicies = action.payload;
    });
    builder.addCase(getConfirmedPolicies.fulfilled, (state, action) => {
      state.confirmedPolicies = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  addOfferedPolicies,
  addActivePolicies,
  addMyPolicies,
  addVoidedPolicies,
  addUnderwrittenPolicies,
  addExpiredPolicies,
  addConfirmedPolicies,
  addPayoutPolicies,
} = policiesSlice.actions;

export default policiesSlice.reducer;
