import React, { useMemo } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Typography,
  Button,
  Stack,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonIcon from "@mui/icons-material/Person";
import { useTheme } from "@mui/material/styles";
import { NavLink, Link } from "react-router-dom";
import { DEFAULT_NAV_LINK, NAV_LINK_OF_LOGGED_ACCOUNT } from "constant";
import { useAppSelector, useAppDispatch } from "redux/store";
import { connectWallet } from "redux/systemState/systemStateSlice";
import { useLoggedAccount } from "hooks/useLoggedAccount";
const AppBarStyled = styled(AppBar)(
  ({ theme }) => `
  box-shadow:${theme.shadows[0]};
`
);

const AvatarStyled = styled(Avatar)(
  ({ theme }) => `
 border:1px solid ${theme.palette.primary.dark};
 background-color:transparent
`
);

const ToolbarStyled = styled(Toolbar)(
  ({ theme }) => `
  color:${theme.palette.common.black};
  height:${theme.spacing(12)}
`
);

const ContainerStyled = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TypoLogoStyled = styled(Typography)`
  font-weight: bold;
`;

const TypoNavStyled = styled(Typography)(
  ({ theme }) => `
  margin-left:${theme.spacing(6)};
 
`
);
const CircleIconStyled = styled(CircleIcon)(
  ({ theme }) => `
  margin-top:${theme.spacing(1.5)};
  font-size:0.6rem
`
);

const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
`;

const LinkStyled = styled(Link)(
  ({ theme }) => `
  text-decoration: none;
  display:flex;
  alignItems:center;
  color:${theme.palette.common.black}
`
);

const Header: React.FC = () => {
  const { connectedUser, isInstalled } = useAppSelector(
    (state) => state.systemState
  );
  const { typeLoggedAccount } = useLoggedAccount();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navLinks = useMemo(() => {
    return NAV_LINK_OF_LOGGED_ACCOUNT[typeLoggedAccount] || DEFAULT_NAV_LINK;
  }, [typeLoggedAccount]);

  return (
    <AppBarStyled color="transparent" position="static">
      <ToolbarStyled variant="regular" disableGutters>
        <ContainerStyled maxWidth="lg">
          <Box display="flex" alignItems="center">
            <Box display="flex">
              <LinkStyled to="/">
                <TypoLogoStyled variant="h6">PRMX</TypoLogoStyled>
                <CircleIconStyled color="primary" />
              </LinkStyled>
            </Box>
            {navLinks.map((item) => {
              return (
                <NavLinkStyled
                  key={item.url}
                  to={item.url}
                  style={({ isActive }) => {
                    return {
                      color: isActive
                        ? theme.palette.primary.main
                        : theme.palette.grey[500],
                    };
                  }}
                >
                  <TypoNavStyled variant="subtitle2">
                    {item.label}
                  </TypoNavStyled>
                </NavLinkStyled>
              );
            })}
          </Box>
          {isInstalled && (
            <>
              {/* U1 Button for sending request to connect wallet */}
              {!connectedUser && (
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<ArrowForwardIcon />}
                  onClick={() => {
                    dispatch(connectWallet());
                  }}
                >
                  Connect Wallet
                </Button>
              )}
              {connectedUser && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography color="primary">{connectedUser}</Typography>

                  <AvatarStyled>
                    <PersonIcon color="primary" />
                  </AvatarStyled>
                </Stack>
              )}
            </>
          )}
        </ContainerStyled>
      </ToolbarStyled>
    </AppBarStyled>
  );
};
export default React.memo(Header);
