export const INSURANCE_EVENT: { [key: string]: string } = {
  earthQuake: "Earth Quake",
  tsunami: "Tsunami",
  typhoon: "Typhoon",
  monsun: "Monsun",
};

export const STATE = [
  { value: 0, label: "Offered" },
  { value: 1, label: "Voided" },
  { value: 2, label: "Underwritten" },
  { value: 3, label: "Peril event confirmed" },
  { value: 4, label: "Paid out" },
  { value: 5, label: "Underwritten policy expired" },
];

export const NAV_LINK_USER = [
  { label: "Policies", url: "/policies" },
  { label: "My Policies", url: "/my-policies" },
  { label: "Request Policy", url: "/request-policy" },
];
export const NAV_LINK_SMART_CONTRACT_OWNER = [
  {
    label: "Policies",
    url: "/smart-contract-owner-policies",
  },
  {
    label: "Set Oracle",
    url: "/set-oracle",
  },
];

export const NAV_LINK_ORACLE = [
  {
    label: "Policies",
    url: "/oracle-policies",
  },
];

export const DEFAULT_NAV_LINK = [{ label: "Policies", url: "/policies" }];

export const TABLE_COLUMNS = [
  "State",
  "IpfsHash",
  "Expiration BlockHeight(ms)",
  "Insured Address",
  "Underwrite Amount",
  "Premium Amount",
];

export const NAV_LINK_OF_LOGGED_ACCOUNT: {
  [key: string]: { label: string; url: string }[];
} = {
  scOwner: NAV_LINK_SMART_CONTRACT_OWNER,
  user: NAV_LINK_USER,
  oracle: NAV_LINK_ORACLE,
};

export const KEY_REQUEST_POLICY = "request-policy";
