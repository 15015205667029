import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
import { routes } from "./config";
import { LinearProgress } from "@mui/material";
import { useLoggedAccount } from "hooks/useLoggedAccount";
import Page404 from "../components/Layout/Page404";
import Page403 from "../components/Layout/Page403";
import { TConfigRouter } from "./interfaces";

const RouterComponent: React.FC = () => {
  const { typeLoggedAccount } = useLoggedAccount();
  const getElement = (route: TConfigRouter) => {
    const Element = route.element;
    if (!route.isAbleToAccess || typeLoggedAccount === route.isAbleToAccess) {
      return <Element />;
    }
    return <Page403 />;
  };

  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route element={<Layout />}>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={getElement(route)}
              />
            );
          })}
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
export default RouterComponent;
