import { ethers } from "ethers";
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS as string;
import contractABI from "../ParametricInsurance.json";
import { STATE, KEY_REQUEST_POLICY } from "../constant";
import { Policy, RequestPolicy } from "../interfaces";

let provider: ethers.providers.Web3Provider;

export const getProvider = () => {
  if (provider) {
    return provider;
  }

  provider = new ethers.providers.Web3Provider(window.ethereum) || undefined;
  return provider;
};

export const getContract = () => {
  const provider = getProvider();

  if (!provider) {
    throw Error('WEB3 PROVIDER COULD NOT BE INSTANTIATED!');
  }

  return new ethers.Contract(
    contractAddress,
    contractABI.abi,
    provider
  );
};

export const getContractForSigner = (account?: string) => {
  const provider = getProvider();
  const signer = provider.getSigner(account);
  return new ethers.Contract(contractAddress, contractABI.abi, signer);
};

export const getState = (stateEnum: number) => {
  return (
    STATE.find((item) =>item.value === stateEnum)?.label || ""
  );
};

export const mapPolicyDtoToPolicy = (policyDto): Policy => {
  const {
    state,
    ipfsHash,
    premiumAmount,
    underwriteAmount,
    expirationBlockHeight,
    insuredAddress,
    insuredNftId,
    underwriterNftId,
  } = policyDto;

  return {
    state: state as number,
    ipfsHash: ipfsHash as string,
    premiumAmount: ethers.BigNumber.from(premiumAmount).toNumber(),
    underwriteAmount: ethers.BigNumber.from(underwriteAmount).toNumber(),
    expirationBlockHeight: ethers.BigNumber.from(
      expirationBlockHeight
    ).toNumber(),
    insuredAddress,
    insuredNftId: ethers.BigNumber.from(insuredNftId).toNumber(),
    underwriterNftId: ethers.BigNumber.from(underwriterNftId).toNumber(),
  };
};

export const getRequestPolicies: () => RequestPolicy[] = () => {
  const jsonRequestPolicies = localStorage.getItem(KEY_REQUEST_POLICY);
  return jsonRequestPolicies
    ? JSON.parse(jsonRequestPolicies)
    : [];
};

export const setRequestPolicies = (requestPolicy: RequestPolicy) => {
  const requestPolicies = [requestPolicy, ...getRequestPolicies()];
  localStorage.setItem(KEY_REQUEST_POLICY, JSON.stringify(requestPolicies));
};

export const deleteRequestPolicy = (ipfsHash: string) => {
  const requestedPolicies = getRequestPolicies();
  //list new requested policies after removing the item that become offered policy
  const newRequestedPolicies = requestedPolicies.filter((item) => {
    return item.ipfsHash !== ipfsHash;
  });
  if (newRequestedPolicies.length === 0) {
    localStorage.removeItem(KEY_REQUEST_POLICY);
    return;
  }
  localStorage.setItem(
    KEY_REQUEST_POLICY,
    JSON.stringify(newRequestedPolicies)
  );
};

export const msToTime = (ms: number) => {
  const seconds = (ms / 1000).toFixed(1);
  const minutes = (ms / (1000 * 60)).toFixed(1);
  const hours = (ms / (1000 * 60 * 60)).toFixed(1);
  const days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (Number(seconds) < 60) return seconds + " Sec";
  else if (Number(minutes) < 60) return minutes + " Min";
  else if (Number(hours) < 24) return hours + " Hrs";
  else return days + " Days";
};
