//U1 connect and disconnect wallet
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getContractForSigner } from "ultils";

export type SystemState = {
  name: string;
};

const initialState: SystemState = {
  name: "oracle",
};
export const setOracle = createAsyncThunk(
  "oracle/setOracle",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ signer, address }: { signer?: string; address: string }) => {
    const contract = getContractForSigner(signer);
    if (signer && contract) {
      return new Promise((resolve, reject) => {
        contract
          .setOracle(address)
          .then((data: any) => {
            resolve(data);
          })
          .catch((err: any) => {
            reject(err.reason || err.message || "");
          });
      });
    }
  }
);

export const oracleSlice = createSlice({
  name: "oracle",
  initialState,
  reducers: {},
});

export default oracleSlice.reducer;
