import { configureStore } from "@reduxjs/toolkit";
import systemStateReducer from "./systemState/systemStateSlice";
import policiesReducer from "./policies/policiesSlice";
import oracleReducer from "./oracle/oracleSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { save, load } from "redux-localstorage-simple"

const rootReducer = {
  systemState: systemStateReducer,
  policies: policiesReducer,
  oracle: oracleReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  // this middleware + config is neccessary
  // to avoid "A non-serializable value was detected in the state" errors
  // see: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        'policies/offerPolicy/fulfilled',
        'policies/expireUnderwrittenPolicy/fulfilled',
        'policies/voidPolicy/fulfilled'
      ],
    },
  }).concat(save({ states: ["policies"] })),
  preloadedState: load({ states: ["policies"] }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
