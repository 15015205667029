export const rpcEndpoint = "https://rpc.api.moonbase.moonbeam.network";
export const networkId = "0x507"; // ~moonbase alpha
export const networkConfig = {
  chainId: networkId,
  chainName: "Moonbase Alpha",
  nativeCurrency: {
    name: "DEV",
    symbol: "DEV",
    decimals: 18,
  },
  rpcUrls: [rpcEndpoint],
  blockExplorerUrls: ["https://moonbase.moonscan.io/"],
};