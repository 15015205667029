import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import Header from "./Header";
import { useAppSelector } from "redux/store";

const Layout: React.FC = () => {
  const { isInstalled } = useAppSelector((state) => state.systemState);

  return (
    <>
      <Header />
      <Container maxWidth="lg">
        {!isInstalled && (
          <Typography
            sx={{
              marginTop: "32px",
              fontStyle: "italic",
            }}
            variant="body1"
            color="error"
            align="center"
          >
            {`
              Currently, MetaMask isn't installed in your browser.
              Please install to proceed.
            `}
          </Typography>
        )}
        <Outlet />
      </Container>
    </>
  );
};
export default React.memo(Layout);
